import React from 'react'

import { motion } from "framer-motion"

const list = {
  visible: {
      opacity: 1,

      transition: {
          when: "beforeChildren",
          staggerChildren: 0.3,
          
      },
  },
  hidden: { opacity: 0 },

}
const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
const Hizmetlerimiz = () => {
    return (
        <motion.div initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-6 py-10'>
            <div className='col-start-2 col-end-6   space-y-5 '>
                <div>
                     <motion.img variants={itemText} src='/images/hizmetlerimiz/2.svg'/>
                     <div className=' w-full md:w-1/2 ' >
                     <motion.h5 variants={itemText} className='text-35 font-bold my-3 '>Gayrimenkul Yatırımı</motion.h5>
                     <motion.p variants={itemText} className='text-18'>Ulus İnşaat, Inşaat, Taahhüt, Mimarlık Ve 25 Yılı Aşkın Süredir Sektördeki Birikimiyle, Gayrimenkul Yatırımlarında Da Öncü Bir Rol Oynamaktadır. Güçlü Altyapısı Ve Deneyimli Ekibiyle Ulus İnşaat, Sürdürülebilir Ve Değerli Gayrimenkul Projeleriyle Yatırımcılarına Güven Veren Bir Çözüm Ortağı Olmaktadır</motion.p>
                     </div>
                </div>
                <div>
                     <motion.img variants={itemText} src='/images/hizmetlerimiz/1.svg'/>
                     <div >
                     <motion.h5 variants={itemText} className='text-35 font-bold my-3'>Müteahhitlik Hizmeti</motion.h5>
                     <motion.p variants={itemText} className='text-18'>Ulus İnşaat, Müteahhitlik Hizmetlerinde Kalite Standartlarına Ve Güven Ilkesine Yüksek Öncelik Vermektedir. Projelerimizde Kullanılan Malzeme Seçiminden Işçilik Kalitesine Kadar Her Aşamada Üst Düzey Standartları Koruyor Ve Müşterilerimize Kaliteli Sonuçlar Sunuyoruz. Ulus İnşaat, Müteahhitlik Hizmetlerinde En Son Teknolojiyi Ve Inovasyonu Kullanarak Projelerini Yönetir. Sürekli Olarak Sektörel Gelişmeleri Takip Ediyor Ve Bu Doğrultuda Iş Süreçlerimizi Optimize Ediyoruz. Bu Sayede, Projelerimizi Daha Verimli, Güvenilir Ve Sürdürülebilir Hale Getiriyoruz.</motion.p>
                     </div>
                </div>
                <div>
                     <motion.img variants={itemText} src='/images/hizmetlerimiz/3.svg'/>
                     <div >
                     <motion.h5 variants={itemText} className='text-35 font-bold my-3'>İnşaat Projeleri</motion.h5>
                     <motion.p variants={itemText} className='text-18 '>Gayrimenkul Yatırımında Kalite Ve Estetik Ön Planda Olduğu Için, Ulus İnşaat Projeleri Özenle Tasarlanmış Ve Inşa Edilmiştir. Her Detay, Müşteri Memnuniyetini Ve Uzun Vadeli Değeri Ön Planda Tutacak Şekilde Planlanmaktadır. Sektördeki En Güncel Teknoloji Ve Inovasyonu Kullanarak, Yatırımcılarımıza Özel, Sürdürülebilir Ve Kazançlı Projeler Sunuyoruz.</motion.p>
                     </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Hizmetlerimiz