import React from 'react'
import { Link } from 'react-router-dom'

const HomeSection3 = () => {
  return (
    <div className="grid sm:grid-cols-8   bg-white  w-full overflow-x-auto sm:overflow-auto">
    <div className="col-start-2 col-end-8 h-full w-full  flex sm:grid sm:grid-cols-2 py-20 sm:py-32 gap-5">
      <div className="w-[60vw] sm:w-auto h-60 sm:h-auto group hover:scale-105 transition-all duration-500">

        <Link to={'/u-ofis'} className="relative h-full cursor-pointer block">
          <img src="/images/U-Ofis.jpg" className="w-full h-full object-cover" />
          <div className="absolute w-full  top-0 left-0 transition-all duration-500 group-hover:h-full h-[22%]">
            <div className="relative h-full w-full">
              <div className="absolute w-full h-full  bg-ulusOrange mix-blend-color"></div>
              <div className=" py-4 w-full h-full top-0 left-0  flex justify-center items-center text-18 sm:text-35 text-white font-bold">U-OFİS</div>
            </div>
          </div>


        </Link>

        <div className="h-6 w-full hidden sm:block "></div>
      </div>

      <div className="w-[60vw] sm:w-auto h-60 sm:h-auto group hover:scale-105 transition-all duration-500">
        <div className="h-6 w-full  hidden sm:block"></div>
        <Link to={'/biten-projeler'} className="relative h-full cursor-pointer block">
          <div className="absolute w-full  top-0 left-0 transition-all duration-500 group-hover:h-full h-[22%]">
            <div className="relative h-full w-full">
              <div className="absolute w-full h-full  bg-ulusOrange mix-blend-color "></div>
              <div className=" py-4 w-full h-full top-0 left-0  flex justify-center items-center text-18 sm:text-35 text-white font-bold">Biten Projeler</div>
            </div>
          </div>
          <img src="/images/home/ulus-4.jpg" className="w-full h-full object-cover" />
        </Link>
      </div>

    </div>

  </div>  )
}

export default HomeSection3