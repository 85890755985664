import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { motion } from "framer-motion"
import { useLenis } from '@studio-freight/react-lenis';

const SliderComponent = () => {
  const lenis = useLenis();
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  const handleIndicatorClick = (index) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index);
    }
  };

  const handlePrevClick = () => {
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  };

  useEffect(() => {
    const swiper = document.querySelector('.swiper').swiper;
    setSwiperInstance(swiper);
    setTotalSlides(swiper.slides.length);
  }, []);

  const calculateProgressWidth = () => {
    if (totalSlides === 0) return '0%';
    const percentage = ((activeIndex + 1) / totalSlides) * 100;
    return `${percentage}%`;
  };


  const handleDownArrowClick = () => {
    if (lenis) {
      const viewportHeight = window.innerHeight;
      lenis.scrollTo(viewportHeight + 30);
    }
  };
  

  return (
    <div>
      <div className="h-[calc(100vh-9.5rem)] w-full bg-black">
        <Swiper
          className="h-full w-full"
          modules={[EffectFade, Autoplay]}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          onSlideChange={handleSlideChange}
          speed={2000}
          autoplay={{ delay: 3000 }}
        >
          <SwiperSlide className="relative">
            <div className="absolute h-full w-full grid grid-cols-6 z-40">
              <div className="col-start-2 col-end-6 w-full h-full py-[5rem]">
                <div className="h-full w-full">
                  <motion.h1 animate={{ translateX:0,opacity: activeIndex === 0 ? [0, 1] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 ,translateX:-20}} className="responsive-63-45  font-bold text-white mb-5">
                    25 Yıllık Deneyim
                    <br />
                    Güvencesiyle…
                  </motion.h1>
                  <motion.p animate={{ translateX:0,opacity: activeIndex === 0 ? [0, 1] : 0, transition: { duration: 2, ease: 'easeIn' } }} initial={{ opacity: 0 ,translateX:-30}} className="text-white text-16 tracking-[0.06rem]">
                    Güçlü İnşaat Taahhüt Mimarlık Altyapımızla, 25
                    <br />
                    Yılın Üzerindeki Birikimimizi Ve Deneyimimizi
                    <br />
                    Başarıyla Birleştirerek İnşaat Yapı Sektöründe
                    <br />
                    Deneyimli Güven Veren Firmasıyız.
                  </motion.p>
                </div>
              </div>
            </div>
            <motion.img animate={{ scale: activeIndex === 0 ? [1.1, 1,1.1] : 1,  transition: { duration: activeIndex=== 0 ?15 :1, ease: 'easeIn',   repeat: Infinity,repeatType: 'loop', } }}   className='h-full w-full object-cover' src='/images/slider/slider1.jpg' />
            {/* <img src="/images/slider/slider1.jpg" className="h-full w-full object-cover" /> */}
          </SwiperSlide>
          <SwiperSlide>
            
          <motion.img animate={{ scale: activeIndex === 1 ? [1.1, 1,1.1] : 1,  transition: { duration: activeIndex=== 1 ?15 :1, ease: 'easeIn',   repeat: Infinity,repeatType: 'loop', } }}   className='h-full w-full object-cover' src='/images/slider/slider2.jpg' />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/slider/slider3.jpg" className="h-full w-full object-cover" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/slider/slider4.jpg" className="h-full w-full object-cover" />
          </SwiperSlide>

        </Swiper>
      </div>

      <div className="h-14 w-full flex flex-col bg-black  border-b-[1px]  border-b-[#151515]">
        <div className="h-1 w-full bg-ulusOrange transition-all duration-1000 ease-in-out" style={{ width: calculateProgressWidth() }}></div>
        <div className="grid grid-cols-8 h-full">
          <div className="col-start-2 col-end-8 h-full w-full grid grid-cols-3">
            <div className="h-full flex items-center space-x-3">
              <a href='https://www.facebook.com/ulusinsaat' target='_blank'><img src="/images/social/1.svg" className="h-6" /></a>
              <a href='https://www.instagram.com/ulus.tr/' target='_blank'><img src="/images/social/2.svg" className="h-6" /></a>
              {/* <img src="/images/social/3.svg" className="h-6"/>
              <img src="/images/social/4.svg" className="h-6"/>
              <img src="/images/social/5.svg" className="h-6"/> */}
            </div>
            <div className="h-full w-full flex justify-center items-center">
      <img
        src="/images/icons/down-arrow.svg"
        className="h-2 cursor-pointer"
        onClick={handleDownArrowClick}
      />
    </div>
            <div className="h-full w-full flex items-center justify-end space-x-2">
              <img
                src="/images/icons/left-slider.svg"
                className="h-6 cursor-pointer"
                onClick={handlePrevClick}
              />
              <div className="h-full flex items-center justify-center space-x-1">
                {Array.from({ length: totalSlides }).map((_, index) => (
                  <div
                    key={index}
                    className={`h-1.5 w-1.5 ${
                      index === activeIndex ? 'bg-white' : 'border border-white'
                    } rounded-full cursor-pointer`}
                    onClick={() => handleIndicatorClick(index)}
                  ></div>
                ))}
              </div>
              <img
                src="/images/icons/right-slider.svg"
                className="h-6 cursor-pointer"
                onClick={handleNextClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderComponent;
