import React from 'react'
import { motion } from 'framer-motion'
const list = {
    visible: {
        opacity: 1,
  
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.3,
            
        },
    },
    hidden: { opacity: 0 },
  
  }
  
  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  const itemWidth = { visible: { height:'100%', transition: { duration: 1.5,delay:0.3 } }, hidden: { height:'0%'}, }

const HomeSection2 = () => {
  return (
    <motion.div initial="hidden" whileInView="visible"  className=" flex  max-h-max  w-full relative">
    <div className="flex space-x-2 w-full  relative ">
      <div className="sm:w-3/4 relative  h-full">
        <motion.img variants={ { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0.5 }, }} className="w-full" src="/images/home/ulus-3.jpg" />
        <motion.div variants={{visible: { width:'50%' ,height:'90%', transition: { duration: 1 } }, hidden: { width:'0%' ,height:'0%'}}} className={`  bg-ulusOrange mix-blend-color absolute right-0 bottom-0`}></motion.div>
      </div>
      <div className="w-3 min-h-max  flex items-end">
      <motion.div variants={{visible: {  height:'90%', transition: { duration: 1 } }, hidden: {height:'0%'}}} className="w-full  bg-ulusOrange"></motion.div>
      </div>
    </div>
    <div className="absolute h-3/4  top-0 left-0 w-full bg-black -z-10 "></div>
  </motion.div>
  )
}

export default HomeSection2