import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom';


const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: { opacity: 1 },
  };


  
const itemText = {visible: { opacity: 1, transition: { duration: 1 } } , hidden: { opacity: 0 }};
const itemButton = { visible: { opacity:1,scale: 1, transition: { duration: 1 } }, hidden: {scale:1.1 ,opacity: 0 }, }
const UPort = () => {
    return (
        <div className=''>
            <motion.section  initial="hidden" whileInView="visible" variants={list} >
                <motion.div variants={itemText} className=' grid grid-cols-6 w-full bg-black relative mt-20'>
                    <div className='absolute top-0 left-0 w-full h-[38%] z-10 bg-white'></div>
                    <div className='col-start-2 col-end-6  h-full relative z-20'>
                        <img src='/images/new-projects/banner.jpg' className='w-full' />


                    </div>
                </motion.div>

                <div className=' grid grid-cols-6 w-full bg-black relative py-20'>
                    <div className='col-start-2 col-end-6  h-full relative z-20'>
                        <motion.img variants={itemText} src='/images/new-projects/u-port-logo.svg' className='w-[16.6] mb-10' />
                        <motion.p variants={itemText} className='text-white text-18 w-full md:w-[58%]'>Denizli’nin Ve Bölgenin Ticaret Potansiyelinden Tam Anlamıyla Faydalanmak Için U-PORT Sizlere Kapılarını Aralıyor. Burası, Sadece Bir Ticaret Alanı Değil, Aynı Zamanda Iş Dünyasında Fark Yaratma Fırsatı Sunan Bir Ticaret Alanıdır.</motion.p>
                    </div>

                </div>
            </motion.section>



            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <div className=' grid grid-cols-6  w-full bg-white relative py-32'>


                    <motion.div variants={itemText} className='col-start-2 col-end-6  h-full relative z-20  space-y-6'>
                        <h5 className='responsive-63-35 font-bold'>Prestijli Markalara <br /> Uygun Kiralama İşyerleri</h5>
                        <div className='h-1 w-12 bg-ulusOrange'></div>
                        <p className='text-black text-18 w-full md:w-[58%] '>Projede Sunulan Iş Yerleri, Prestijli Markalar Ve Yüksek Standartları Olan Işletmeler Için Özel Olarak Tasarlanmıştır. Şık Tasarımı Ve Kaliteli Yapısı Ile Bu Iş Yerleri, Markanızın Görünürlüğünü Ve Itibarını Artırarak Müşteri Çekme Potansiyelini Maksimize Eder. Prestijli Bir Adreste Yer Almanın Getirdiği Avantajlarla Işiniz, Sektörünüzde Öne Çıkacak. </p>
                    </motion.div>


                </div>
            </motion.section>


            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <div className=' bg-black relative pt-32 lg:py-0 space-y-5 lg:space-y-0'>
                    <img src='images/new-projects/deger-artis.png'  className='hidden lg:block lg:w-full '/>

                    <motion.div variants={itemText} className='relative lg:absolute h-full w-full top-0 left-0 text-white grid grid-cols-6 content-center'>
                        <div className='col-start-2 col-end-6  h-full relative z-20  space-y-6'>
                            <h5 className='responsive-63-35 font-bold'>Değer Artış Potansiyeli</h5>
                            <div className='h-1 w-12 bg-ulusOrange'></div>
                            <p className=' text-18 w-full md:w-[58%]'>Projenin Bulunduğu Lokasyon Ve Yüksek Kaliteli Yapı Özellikleri, Zaman Içinde Değer Kazanmasını Sağlıyor. Orta Vadede, Bölgesel Gelişmeler Ve Altyapı Iyileştirmeleri Ile Birlikte Projenin Değeri Artacak, Bu Da Yatırımcılara Önemli Maddi Getiriler Sunacak. </p>

                        </div>
              
                    </motion.div>
                    <img src='/images/new-projects/mobile-deger-artis.png' className='w-full block lg:hidden'/>


                </div>
            </motion.section>

            {/* <section>
                <div className='  w-full bg-black relative py-32'>




                    <img src='/images/new-projects/banner-hook.svg' className=' object-cover h-full  w-full  top-0 right-0  bg-red-400'/>
        
                        <div className='grid grid-cols-6 '>
                    <div className='col-start-2 col-end-6  h-full absolute z-20  space-y-6 top-0 left-0'>
                        <h5 className='responsive-63-35 font-bold text-white'>Değer Artış Potansiyeli</h5>
                        <div className='h-1 w-12 bg-ulusOrange'></div>
                        <p className='text-white text-18 w-[50%]'>Projenin Bulunduğu Lokasyon Ve Yüksek Kaliteli Yapı Özellikleri, Zaman Içinde Değer Kazanmasını Sağlıyor. Orta Vadede, Bölgesel Gelişmeler Ve Altyapı Iyileştirmeleri Ile Birlikte Projenin Değeri Artacak, Bu Da Yatırımcılara Önemli Maddi Getiriler Sunacak. </p>
                    </div>
                    </div>


                </div>
            </section> */}


            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <div className=' grid grid-cols-6  w-full bg-white relative py-32'>


                    <motion.div variants={itemText} className='col-start-2 col-end-6  h-full relative z-20  space-y-6'>
                        <h5 className='responsive-63-35 font-bold'>Stratejik Konum</h5>
                        <div className='h-1 w-12 bg-ulusOrange'></div>
                        <p className='text-black text-18 w-full md:w-[58%]'>Bu Proje, Ana Ulaşım Yollarına Ve Önemli Iş Merkezlerine Yakınlığı Ile Dikkat Çekiyor. Lokasyonunun Stratejik Avantajları, Projenin Değerini Zamanla Artırıyor Ve Yatırımınızın Geleceğini Güvence Altına Alıyor.</p>
                    </motion.div>


                </div>
            </motion.section>


            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <div className='w-full relative'>
                    <img src='/images/new-projects/bloks.jpg' className='w-full ' />
                    <motion.div variants={itemText} className='w-[8.6%] aspect-square bg-[rgba(255,255,255,0.66);] rounded-full absolute top-[7.84%] left-[36%] flex justify-center items-center text-[1.5vw] font-bold'>
                        C-BLOK
                    </motion.div>

                    <motion.div variants={itemText} className='w-[8.6%]  aspect-square bg-[rgba(255,255,255,0.66);] rounded-full absolute top-[16.1%] left-[50.4%] flex justify-center items-center text-[1.5vw] font-bold'>
                        B-BLOK
                    </motion.div>
                    <motion.div variants={itemText} className='w-[8.6%]  aspect-square bg-[rgba(255,255,255,0.66);] rounded-full absolute top-[23.3%] left-[66%] flex justify-center items-center text-[1.5vw] font-bold'>
                        A-BLOK
                    </motion.div>
                </div>
            </motion.section>


            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <div className=' relative pt-32 lg:py-0 bg-black lg:bg-transparent'>
                    <motion.img variants={itemText} src='images/new-projects/yuksek-kalite.png' className='hidden lg:block'/>
                    <div className='relative lg:absolute h-[90%] w-full bg-black top-0 left-0 -z-10'></div>

                    <motion.div variants={itemText} className='relative lg:absolute h-full w-full top-[28%] left-0 text-white grid grid-cols-6  pb-10'>
                        <div className='col-start-2 col-end-6  h-full relative z-20  space-y-6'>
                            <h5 className='responsive-63-35 font-bold'>Yüksek Kalite Standartları</h5>
                            <div className='h-1 w-12 bg-ulusOrange'></div>
                            <p className=' text-18 w-full md:w-[58%] '>İnşaat Ve Tasarımda Kullanılan Yüksek Kalite Malzemeler, Modern Mimari Anlayışı Ve Işlevsel Tasarım, Projeyi Piyasadaki Diğer Emlak Projelerinden Ayırıyor. Yüksek Standartlar, Uzun Vadede Projenin Çekiciliğini Ve Değerini Koruyor.</p>

                        </div>
                    </motion.div>

                <div  className='flex lg:hidden justify-center relative'>
                    <motion.img variants={itemText} src='/images/new-projects/5.jpg' className='w-1/2 relative z-10'/>
                    <div className='h-1/2 w-full bg-white absolute bottom-0'></div>

                    </div>


                </div>
            </motion.section>



            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <motion.div variants={itemText} className=' grid grid-cols-6  w-full bg-white relative py-32 pt-20'>


                    <div className='col-start-2 col-end-6  h-full relative z-20  space-y-6'>
                        <h5 className='responsive-63-35 font-bold'>
                            Ticari Alanlar İle Yatırıma
                            <br />
                            Ve Kazanmaya Hemen Başlayın</h5>
                        <div className='h-1 w-12 bg-ulusOrange'></div>
                        <p className='text-black text-18 w-[58%]'>Denizli’nin Gözbebeği,
                            <br />
                            Tüm Bağlantılara Bir Adım Mesafede
                            <br />
                            Ticaret Alanları...</p>
                    </div>


                </motion.div>
            </motion.section>


            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <div variants={itemText} className=' grid grid-cols-6  w-full bg-black relative py-32'>


                    <motion.div variants={itemText} className='col-start-2 col-end-6  h-full relative z-20  space-y-6'>
                        <h5 className='responsive-63-35 font-bold text-white'>
                            Denizli’nin Gözbebeği,
                            <br />
                            Tüm Bağlantılara Bir Adım Mesafede
                            <br />
                            Ticaret Alanları…
                        </h5>
                        <div className='h-1 w-12 bg-ulusOrange'></div>
                        <p className=' text-white text-18 w-full md:w-[58%]'>
                            U-PORT Ticaret Alanları, Iş Dünyasının Kalbinde Yer Alıyor. İzmir-Denizli Yolunun Hemen Yanı Başında Konumlanan Bu Prestijli Ticaret Alanı, Organize Sanayi Bölgesi’ne Komşu Olup Denizli Merkezine Olan Yakınlığı Ile Benzersiz Bir Ulaşım Kolaylığı Sunar. İster Hafif Bir Araçla Şehir Merkezine Çabucak Ulaşın, Ister Ağır Taşıtlarınızla Bölgenin Ana Arterlerine Hızla Bağlanın; U-PORT Ticaret, Işlerinizi Büyütme Ve Yönetme Konusunda Size Zaman Ve Konfor Sunuyor.
                        </p>
                    </motion.div>


                </div>
            </motion.section>


            <section>
                <img src='/images/new-projects/map.jpg' className='w-full ' />
            </section>

            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <div className=' grid grid-cols-6  w-full bg-white relative py-32'>


                    <motion.div variants={itemText}  className='col-start-2 col-end-6  h-full relative z-20  space-y-6'>
                        <h5 className='responsive-63-35 font-bold'>
                            U-PORT Ticaret Alanların’dan
                            Her Yere!
                        </h5>
                        <div className='h-1 w-12 bg-ulusOrange'></div>
                        <p className='  text-18 w-full md:w-[58%]'>
                            Denizli’nin Tam Kalbinde, Şehir Hayatının Tüm Ticari Merkezlerine Bir Adım Mesafede Yer Alan Bu Özel Lokasyon, Hayalinizdeki Yaşamı Gerçekleştirmek Için Sizi Bekliyor.
                        </p>
                        <p className='  text-18 w-full md:w-[58%]'>
                            Modern Ticaret Hayatının Tüm Kolaylıklarını Ayağınıza Getiren Bu Benzersiz Adres, Şehrin En Önemli Bağlantı Yollarına Olan Yakınlığıyla Benzersiz Ticaret Alanları…
                        </p>
                    </motion.div>


                </div>
            </motion.section>


            <section>
                <img src='/images/new-projects/map-2.jpg' className='w-full ' />
            </section>


            {/* <section>
                <div className='  relative bg-black'>
                    <img src='images/new-projects/isyeryerleri-ozel.png' className=' z-20'/>
                    <div className='absolute h-[23.4%] w-full bg-white top-0 left-0 z-10'></div>

                    <div className='absolute h-full w-full top-[28%] left-0 text-white grid grid-cols-6  '>
                        <div className='col-start-2 col-end-6  h-full relative z-20  space-y-6'>
                            <h5 className='responsive-63-35 font-bold'>Değer Artış Potansiyeli</h5>
                            <div className='h-1 w-12 bg-ulusOrange'></div>
                            <p className=' text-18 w-[58%]'>Projenin Bulunduğu Lokasyon Ve Yüksek Kaliteli Yapı Özellikleri, Zaman Içinde Değer Kazanmasını Sağlıyor. Orta Vadede, Bölgesel Gelişmeler Ve Altyapı Iyileştirmeleri Ile Birlikte Projenin Değeri Artacak, Bu Da Yatırımcılara Önemli Maddi Getiriler Sunacak. </p>

                        </div>
                    </div>

   
                </div>
            </section> */}

            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <div className='relative bg-black  lg:bg-transparent py-32 lg:py-0 px-[16.6%] lg:px-0'>
                    <div className='h-[75%] w-full absolute bottom-0 left-0 bg-black z-10'></div>
                    <motion.img variants={itemText}  src='/images/new-projects/isyeryerleri-ozel.png' className=' hidden lg:block   w-full    relative z-10' />
                    <motion.div variants={itemText}  className='w-full  lg:w-1/2  static lg:absolute top-[33%] left-[40%]  z-50 content-center'>
                        <div className='col-start-2 col-end-6  h-full relative z-20  space-y-6 '>
                            <h5 className='responsive-63-35 font-bold text-white'>
                                U-Port Ticaret Işyerleri
                                <br />
                                Özel Projesi, Her Bir Detayın
                                <br />
                                <span className='font-light'>
                                İnce Ince Düşünülerek Tasarlandığı
                                <br />
                                Bir Yatırım Fırsatı Sunuyor.
                                </span>
                            </h5>
                            <div className='h-1 w-12 bg-ulusOrange'></div>
                            <p className='  text-18 w-full  xl:w-[100%] text-white'>
                                Her Bir Işyeri Için 1080 M² Arsa Payı, 550 M² Kapalı Alan Ve 200 M² Yarı Açık Alan Sunan Bu Özel Proje, Üç Bloktan Ve Toplamda 13 Adet Iş Yerinden Oluşmaktadır. Her Bir Detayın Özenle Tasarlandığı Bu Proje, Yatırımcılar Için Benzersiz Bir Fırsat Sunmaktadır. Projemiz, Iş Dünyasının Ihtiyaçlarına Uygun Olarak Planlandı; Her Bir Imkan, Siz Değerli Yatırımcıların Beklentilerini Karşılayacak Şekilde Entegre Edildi. Modern Mimarisi, Geniş Kullanım Alanları Ve Yüksek Standartlardaki Inşaat Kalitesi Ile Bu Proje, Işlerinizi Yeni Bir Seviyeye Taşımak Için Ideal Bir Tercih Olacaktır.
                            </p>

                        </div>
                    </motion.div>

                    
 
                </div>
                <div className='flex lg:hidden justify-center relative bg-black'>
                    <motion.img  variants={itemText}  src='/images/new-projects/6.jpg' className='w-1/2 relative z-30'/>
                    <div className='h-1/2 w-full bg-white absolute bottom-0 z-20'></div>

                    </div>
            </motion.section>



            <section >
                <div className='grid grid-cols-6 py-32 '>

                    <div className='col-start-2 col-end-6  w-full  grid grid-cols-1 md:grid-cols-3 space-y-5 md:space-y-0'>
                        <div className='h-full  flex flex-col items-center'>
                            <div className='text-45 md:text-28 lg:text-55 font-bold'>1080 M2</div>
                            <div className='text-21 md:text-16 lg:text-21 font-semibold text-ulusOrange'>Her Bir Işyeri Için Arsa Payı</div>
                        </div>
                        <div className='h-full  flex flex-col items-center'>
                            <div className='text-45 md:text-28 lg:text-55 font-bold'>550 M2</div>
                            <div className='text-21 md:text-16 lg:text-21 font-semibold text-ulusOrange'>Kapalı Alan</div>
                        </div>
                        <div className='h-full 0 flex flex-col items-center'>
                            <div className='text-45 md:text-28 lg:text-55 font-bold'>200 M2</div>
                            <div className='text-21 md:text-16 lg:text-21 font-semibold text-ulusOrange'>Yarı Açık Alan</div>
                        </div>

                        <div className='col-span-1 md:col-span-3 py-16'>U-PORT Ticaret Alanları, Iş Dünyasının Kalbinde Yer Alıyor. İzmir-Denizli Yolunun Hemen Yanı Başında Konumlanan Bu Prestijli Ticaret Alanı, Organize Sanayi Bölgesi’ne Komşu Olup Denizli Merkezine Olan Yakınlığı Ile Benzersiz Bir Ulaşım Kolaylığı Sunar. İster Hafif Bir Araçla Şehir Merkezine Çabucak Ulaşın, Ister Ağır Taşıtlarınızla Bölgenin Ana Arterlerine Hızla Bağlanın; U-PORT Ticaret, Işlerinizi Büyütme Ve Yönetme Konusunda Size Zaman Ve Konfor Sunuyor. </div>



                    </div>
                </div>
            </section>

            <motion.section initial="hidden" whileInView="visible" variants={list}>
                <motion.div variants={itemText} className=' grid grid-cols-6  w-full bg-white relative  pb-20'>


                    <div className='col-start-2 col-end-6  h-full relative z-20  space-y-6'>
                        <h5 className='responsive-63-35 font-bold'>
                            KATALOG</h5>
                        <div className='h-1 w-12 bg-ulusOrange'></div>
                        <Link className='block' >
            <motion.div whileHover={{scale:1.1}} variants={itemButton}   className="inline-flex space-x-2 py-2 px-6 rounded-3xl border bg-ulusOrange border-white ">
              <h6 className=" text-14 md:text-21 text-white font-bold">KATALOG İÇİN TIKLAYINIZ</h6>
              <img src="/images/icons/kesfet-right.svg" className="w-8" />
            </motion.div>
            </Link>
                    </div>


                </motion.div>
            </motion.section>



            <section >
                <img src='/images/new-projects/last.png' className=' bg-black w-full    relative z-10' />
            </section>

        </div>
    )
}

export default UPort