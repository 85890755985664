import React from 'react'
import { Link } from 'react-router-dom'
import { useLenis } from '@studio-freight/react-lenis';

const footerData = [
    {
        title: 'ANASAYFA',
        link: '/',
    },
    {
        title: 'KURUMSAL',
        link: '/hakkimizda',
        data: [
            {
                title: 'Hakkımızda',
                link: '/hakkimizda'
            },
            {
                title: 'Vizyon',
                link: '/hakkimizda'
            },
            {
                title: 'Misyon',
                link: '/hakkimizda'
            },
        ]
    },
    {
        title: 'HİZMETLERİMİZ',
        link: '/hizmetlerimiz',
        data: [
            {
                title: 'Gayrimenkul Yatırımı',
                link: '/hizmetlerimiz'
            },
            {
                title: 'Müteahhitlik Hizmeti',
                link: '/hizmetlerimiz'
            },
            {
                title: 'Kaliteli İnşaat Projeleri',
                link: '/hizmetlerimiz'
            },
        ]
    },
    {
        title: 'PROJELER',
        link: '/biten-projeler',
        data: [
            {
                title: 'U-port',
                link: '/u-port'
            },
            {
                title: 'Biten Projeler',
                link: '/biten-projeler'
            },
            {
                title: 'Devam eden projeler',
                link: '/devam-eden-projeler'
            },
        ]
    },
    {
        title: 'İLETİŞİM',
        link: '/iletisim',
        data: [
            {
                title: 'Adres',
                link: '/iletisim'
            },
            {
                title: 'Telefon',
                link: '/iletisim'
            },
            {
                title: 'Mail',
                link: '/iletisim'
            },
            {
                title: 'Google map',
                link: '/iletisim'
            },
        ]
    },
]

const Footer = () => {
    const lenis = useLenis();

    const handleScrollToTop = () => {
        if (lenis) {
            lenis.scrollTo(0);
        }
    };

    return (
        <div className='flex flex-col  relative sm:sticky bottom-0 font-poppins-o border-t border-t-white'>
            <div className="grid grid-cols-8  bg-black ">
                <div className="col-start-2 col-end-8 h-full w-full  py-24 flex">
                    <div className='flex flex-col w-full'>
                        <div className=' w-full  flex justify-between mb-20'>
                            <div>
                                <img src='/images/ulus-logo-white.svg' />
                            </div>


                        </div>
                        <div className=' w-full  grid grid-cols-12'>
                            <div className='  col-span-12 md:col-span-3 mb-10 md:mb-0   text-16 text-[rgba(255,255,255,0.6)] font-light'>
                                <div className='!font-medium mb-5'>İletişim</div>
                                <a href='tel:02582137744' target='_blank' className='block mb-2 hover:text-ulusOrange transition-all duration-500'>(0258) 213 77 44</a>
                                <a href='tel:02582137744' target='_blank' className='block mb-16 hover:text-ulusOrange transition-all duration-500'>Mehmetçik Mah.
                                    <br />
                                    Muammer Aksoy Cad. No: 89,
                                    <br />
                                    20100 Pamukkale/Denizli</a>
                                <a href='mailto:info@ulusinsaat.com' target='_blank' className='block hover:text-ulusOrange transition-all duration-500'>info@ulusinsaat.com</a>

                            </div>
                            <div className=' col-span-12 md:col-span-9   grid  grid-cols-1 sm:grid-cols-5 text-16 gap-5'>
                                {
                                    footerData.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <Link to={item.link} className='mb-5 block font-medium hover:text-ulusOrange transition-all duration-500 text-[rgba(255,255,255,0.6)]'>{item.title}</Link>
                                                <div className='flex flex-col text-[rgba(255,255,255,0.6)]'>
                                                    {
                                                        item.data && item.data.map((item, index) => {
                                                            return (
                                                                <Link to={item.link} className='block hover:text-ulusOrange transition-all duration-500'>{item.title}</Link>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className='flex justify-start space-x-2  w-full mt-10 md:hidden'>
                                    <a href='https://www.facebook.com/ulusinsaat' target='_blank'><img className='h-10 w-10' src='/images/social/f-white.svg' /></a>
                                    <a href='https://www.instagram.com/ulus.tr/' target='_blank'><img className='h-10 w-10' src='/images/social/i-white.svg' /></a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='w-16 h-full   flex-col justify-between items-end hidden md:flex'>
                        <div className='flex justify-center space-x-2'>
                            <a href='https://www.facebook.com/ulusinsaat' target='_blank'><img className='h-6 w-6' src='/images/social/f-white.svg' /></a>
                            <a href='https://www.instagram.com/ulus.tr/' target='_blank'><img className='h-6 w-6' src='/images/social/i-white.svg' /></a>
                        </div>
                        <button onClick={handleScrollToTop} className="w-5">
                            <img src="/images/icons/down-arrow-white.svg" alt="Scroll to Top" />
                        </button>


                    </div>

                </div>

            </div>
            <div className="grid grid-cols-8  bg-white    py-5">
                <div className="col-start-2 col-end-8 h-full w-full   grid grid-cols-3 text-12 text-[rgba(0,0,0,0.5)]">
                    <div>© Copyright all reserved Ulus</div>
                    <div></div>
                    <a href='https://macart.com.tr/' target='_blank' className='flex justify-end'>MAC ART</a>
                </div> </div>
        </div>
    )
}

export default Footer