import React from 'react'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'

const HomeSection4 = () => {

    const list = {
        visible: {
            opacity: 1,
      
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.3,
                
            },
        },
        hidden: { opacity: 0 },
      
      }
      
      const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
      const itemButton = { visible: { opacity:1,scale: 1, transition: { duration: 1 } }, hidden: {scale:1.1 ,opacity: 0 }, }
      
  
  return (
    <motion.div initial="hidden" whileInView="visible" variants={list} className="grid grid-cols-8 border-t border-[#707070] bg-white">
    <div className="col-start-2 col-end-8 sm:col-end-6 h-full w-full  py-32 flex flex-col">
      <motion.h4 variants={itemText} className="text-45 font-bold mb-5">Bizi Tanıyın</motion.h4>
      <motion.p variants={itemText} className='text-18'>Yıl 1999’dan beri Ulus olarak, inşaat ve müteahhitlik sektöründe sağladığımız birçok projede, müşterilerimize en üst düzeyde hizmet sunma amacıyla yoğun çaba harcadık. Bu süre zarfında edindiğimiz deneyimlerle birlikte, güvenirlilik, kalite, disiplin ve etik değerleri benimseyen bir firma olarak, müşterilerimizin ihtiyaç ve beklentilerini her aşamada eksiksiz bir şekilde karşılamayı başardık.</motion.p>
      <div>
        <Link className='block'  to={'/hakkimizda'}>
        <motion.div whileHover={{scale:1.1}} variants={itemButton} className=" inline-flex space-x-2 py-2 px-6 rounded-3xl border  border-black mt-7 group cursor-pointer">
          <h6  className="text-21 text-black font-bold ">KEŞFET</h6>
          <img src="/images/icons/kesfet-right-black.svg" className="w-8" />
        </motion.div>
        </Link>
      </div>
    </div>



  </motion.div>
  )
}

export default HomeSection4