import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HeaderMobile from '../components/header/HeaderMobile'

const PageContainer = () => {
  const location = useLocation()
  const [toggle, setToggle] = useState(false)

  const pageVariants = {
    initial: { x: "100%", opacity: 0 },
    in: { x: 0, opacity: 1 },
    out: { x: "-100%", opacity: 0 }
  }

  const pageTransition = {
    type: "tween",
    ease: "easeIn",
    duration: 1
  }

  return (
    <div className='relative'>
      <Header toggle={toggle} setToggle={setToggle} />
      <div className='relative z-40 bg-white overflow-x-hidden'>
      
          <motion.div
            key={location.pathname}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
          >
            <Outlet />
          </motion.div>

        <HeaderMobile toggle={toggle} />
      </div>
      <Footer />
    </div>
  )
}

export default PageContainer
