import { AnimatePresence, motion } from "framer-motion"
import { Route, Routes, useLocation } from "react-router-dom";

import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import UPort from "./pages/UPort";
import PageContainer from "./pages/PageContainer";
import Anasayfa from "./pages/Anasayfa";
import Hakkimizda from "./pages/Hakkimizda";
import Iletisim from "./pages/Iletisim";
import Hizmetlerimiz from "./pages/Hizmetlerimiz";
import BitenProjeler from "./pages/BitenProjeler";

import DevamEdenProjeler from "./pages/DevamEdenProjeler";
import { useEffect } from "react";
import UOfis from "./pages/UOfis";
function App() {
  const location = useLocation();
  const lenis = useLenis();

  useEffect(() => {
    if (lenis) {
      lenis.scrollTo(0, { immediate: true });
    }
  }, [location, lenis]);
  return (
    <ReactLenis root>
    <div className="App ">


      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<PageContainer />} >
              <Route path="/" element={<Anasayfa/>} />
              <Route path="/u-port" element={<UPort/>} />
              <Route path="/hakkimizda" element={<Hakkimizda/>} />
              <Route path="/iletisim" element={<Iletisim/>} />
              <Route path="/hizmetlerimiz" element={<Hizmetlerimiz/>} />
              <Route path="/biten-projeler" element={<BitenProjeler/>} />
              <Route path="/u-ofis" element={<UOfis/>} />
          </Route>
        </Routes>
      </AnimatePresence>








    </div>
    </ReactLenis>
  );
}

export default App;
