import React from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { Link } from 'react-router-dom'

const list = {
  visible: {
      opacity: 1,

      transition: {
          when: "beforeChildren",
          staggerChildren: 0.3,
          
      },
  },
  hidden: { opacity: 1 },

}

const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
const itemButton = { visible: { opacity:1,scale: 1, transition: { duration: 1 } }, hidden: {scale:1.1 ,opacity: 0 }, }

const HomeSection1 = () => {
  const { scrollY } = useViewportScroll();
  
  // Adjust the scroll range (input range) to match the height of the section
  const y1 = useTransform(scrollY, [0, 1000], [0, -50]); // Adjust these values for your desired effect
  const y2 = useTransform(scrollY, [0, 1000], [0, 50]);

  // Check if the screen width is greater than or equal to 640px (sm breakpoint)
  const isDesktop = typeof window !== "undefined" && window.innerWidth >= 640;

  return (
    <motion.div  initial="hidden" whileInView="visible" variants={list} className="bg-black block sm:grid grid-cols-8">
      <div className="col-start-2 col-end-8 h-full w-full py-0 sm:py-36">
        <div className="grid-cols-2 gap-5 block sm:grid">
          <div className="w-full">
            {isDesktop ? (
              <motion.img
                src="/images/home/ulus-1.jpg"
                className="w-[84%] sm:w-full object-cover"
                style={{ y: y1 }}
              />
            ) : (
              <img
                src="/images/home/ulus-1.jpg"
                className="w-[84%] sm:w-full object-cover"
              />
            )}
            <div className="h-32 w-ful items-end hidden sm:flex">
              <motion.img variants={itemText} src="/images/u-port-logo.svg" className="w-1/2" />
            </div>
          </div>
          <div className="w-full flex flex-col items-end">
            <div className="h-10 sm:h-32 w-full"></div>
            {isDesktop ? (
              <motion.img
                src="/images/home/ulus-2.jpg"
                className="w-[62.3%] sm:w-full object-cover"
                style={{ y: y2 }}
              />
            ) : (
              <img
                src="/images/home/ulus-2.jpg"
                className="w-[62.3%] sm:w-full object-cover"
              />
            )}
          </div>
        </div>

        <div className="w-full py-10 grid grid-cols-1 sm:grid-cols-2 px-[12.5%] sm:px-0">
          <div>
            <motion.p variants={itemText} className="text-white text-18  pr-10">
              Denizli’nin Ve Bölgenin Ticaret Potansiyelinden Tam Anlamıyla Faydalanmak Için U-PORT Sizlere Kapılarını Aralıyor. Burası, Sadece Bir Ticaret Alanı Değil, Aynı Zamanda Iş Dünyasında Fark Yaratma Fırsatı Sunan Bir Ticaret Alanıdır.
            </motion.p>
            <Link className='block'  to={'/u-port'}>
            <motion.div whileHover={{scale:1.1}} variants={itemButton}   className="inline-flex space-x-2 py-2 px-6 rounded-3xl border border-white mt-10">
              <h6 className="text-21 text-white font-bold">KEŞFET</h6>
              <img src="/images/icons/kesfet-right.svg" className="w-8" />
            </motion.div>
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default HomeSection1
