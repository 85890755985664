import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Zoom } from 'yet-another-react-lightbox/plugins';

const list = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
  hidden: { opacity: 0 },
};

const itemText = {
  visible: { opacity: 1, transition: { duration: 1 } },
  hidden: { opacity: 0 },
};

const ProjectsContainer = ({ DATA }) => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 w-full p-5 gap-5'>
      {DATA.map((item, index) => (
        <motion.div
          whileInView={{ opacity: 1, transition: { duration: 1 } }}
          initial={{ opacity: 0 }}
          key={index}
          className='aspect-[2/1] relative cursor-pointer group overflow-hidden'
          onClick={() => handleImageClick(index)}
        >
          <div className='h-full w-full absolute top-0 left-0 flex justify-end items-end p-10'>
 <img className=' z-40 w-[4%]  opacity-0 group-hover:opacity-100 transition-all duration-500 ' src='/images/magnifying-glass.svg'/>
 </div>
          <div className='absolute top-0 left-0 h-full w-full p-10 text-28 font-bold text-white z-20'>
            <h6 className='mb-2 lg:mb-5 text-21 lg:text-28'>{item.title}</h6>
            <div className='h-1 w-8 lg:w-12 bg-ulusOrange'></div>
          </div>
          <div className='absolute top-0 left-0 h-full w-full  transition-all duration-500 bg-black opacity-30 group-hover:opacity-0'>
         
          </div>
          <div className='custom-linear-gradient h-full w-full'>
                    <img src={item.image} className='object-cover group-hover:scale-105 transition-all duration-500 -z-10 relative ' />
          </div>

        </motion.div>
      ))}

      {open && (
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={DATA.map((item) => ({ src: item.image }))}
          index={currentIndex}
          plugins={[Zoom]}
        />
      )}
    </div>
  );
};

export default ProjectsContainer;
