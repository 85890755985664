export const DATA = [
    {
      title: 'ANASAYFA',
      link: '/'
    },
    {
      title: 'KURUMSAL',
      link: '/hakkimizda',
      
  
    },
    {
      title: 'HİZMETLERİMİZ',
      link: '/hizmetlerimiz',
  
    },
    {
      title: 'PROJELER',
      link: '/biten-projeler',
      dropDown: [
        {
          title: 'U-Port',
          link: '/u-port',
        },
        {
          title: 'U-Ofis',
          link: '/u-ofis',
        },
        {
          title: 'Biten Projeler',
          link: '/biten-projeler',
        },
        // {
        //   title: 'Devam Eden Projeler',
        //   link: '/devam-eden-projeler',
        // },
  
      ]
    },
    {
      title: 'İLETİŞİM',
      link: '/iletisim',
  
    },
  
  
  ]