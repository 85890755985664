import React from 'react'
import { motion } from "framer-motion"
const list = {
    visible: {
        opacity: 1,
  
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.3,
            
        },
    },
    hidden: { opacity: 0 },
  
  }
  
  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }

const Hakkimizda = () => {
    return (
        <motion.div  initial="hidden" whileInView="visible" variants={list} className=' '>
            <div className='grid grid-cols-6 w-full h-full text-18'>
                <div className='col-start-2 col-end-6 h-full  py-20'>
                    <div className='h-full w-full '>
                        <div className='mb-10 space-y-5'>
                        <motion.h2 variants={itemText} className='text-45 font-bold'>Hakkımızda</motion.h2>
                        <motion.p variants={itemText}>Yıl 1999’Dan Beri Ulus Olarak, Inşaat Ve Müteahhitlik Sektöründe Sağladığımız Birçok Projede, Müşterilerimize En Üst Düzeyde Hizmet Sunma Amacıyla Yoğun Çaba Harcadık. Bu Süre Zarfında Edindiğimiz Deneyimlerle Birlikte, Güvenirlilik, Kalite, Disiplin Ve Etik Değerleri Benimseyen Bir Firma Olarak, Müşterilerimizin Ihtiyaç Ve Beklentilerini Her Aşamada Eksiksiz Bir Şekilde Karşılamayı Başardık.</motion.p>
                        <motion.p variants={itemText}>Firmamız, Sektöre Ait Teknolojik Gelişmeleri Yakından Takip Etme Ve Uygulama Konusundaki Kararlılığı Ile Bilinirken, Başladığı Tüm Projelerde Doğru Ve Ekonomik Çözümleri Hızlı Bir Şekilde Üreterek Zamanında Ve Eksiksiz Bir Şekilde Tamamlamayı Ilke Edindi. Ayrıca, Kalite Sistemleri Çerçevesinde Ekip Çalışmasına Önem Vererek Sürekli Olarak Kalite Standartlarını Yükseltmeye Odaklandık.</motion.p>
                        <motion.p variants={itemText}>Firma Olarak, Topluma Ve Çevreye Saygılı Bir Kuruluş Olma Misyonunu Benimseyerek, Iş Hacmimizi Sürekli Genişleterek Ülke Ekonomisine Ve Işsizliğe Katkı Sağlamayı Hedefledik. Bu Doğrultuda, Yeni Bir Dünya Düzenine Ve Çağın Gereksinimlerine Uyum Sağlamak Amacıyla Logosunu Revize Eden Ulus, Stratejisini Yeni Nesil Bir Kompakt Modelle Birleştirdi.</motion.p>
                        <motion.p variants={itemText}>Yenilenen Logomuz, Ulus’un Çoklu Yapıyla Inşaat Müteahhitliği, Yapı Ve Yap-Al-Sat Hizmetleri Alanında Geniş Bir Yelpazede Faaliyet Göstereceğini Simgeliyor. Bu Değişimle Birlikte, Ulus Olarak Sadece Geçmişte Değil, Gelecekte De Sektördeki Öncü Rolümüzü Sürdürmeye Ve Müşterilerimize En Iyi Hizmeti Sunmaya Kararlılığımızı Yineleyerek, Yeni Bir Başlangıca Adım Atmış Bulunuyoruz.</motion.p>
                        </div>
                        <div className='grid grid-cols-1  md:grid-cols-2 gap-10 md:gap-20 '>
                            <div>
                                <motion.h2 variants={itemText} className='text-24 font-bold mb-2'>ÇEVRE GÜVENLİĞİ</motion.h2>
                                <motion.p variants={itemText}>Çevre Güvenliği, Şirketlere Ve Kurumlara Sürdürülebilir Bir Gelecek Inşa Etmeleri Için Gereken Araçları Sunar. Çevresel Etkileri En Aza Indirme Ve Doğa Dostu Uygulamalarla Çözümler Üretme Konusundaki Kararlılığımızla, Daha Yeşil Bir Dünya Için Birlikte Çalışıyoruz.</motion.p>
                                <motion.p variants={itemText}>Çevre Güvenliği, Çözüm Odaklı Yaklaşımı, Deneyimli Ekibi Ve Güncel Teknolojiyle Çevresel Hedeflere Ulaşmanızı Sağlamak Için Burada. Geleceği Korumak Adına Bize Katılın, Çünkü Biz Çevreye Değer Veriyoruz!
                                </motion.p>
                            </div>
                            <div>
                                <motion.h2 variants={itemText} className='text-24 font-bold mb-2'>ORTAK DEĞERLERİMİZ</motion.h2>
                                <motion.p variants={itemText}>Ulus İnşaat Ve Çevre Güvenliği Olarak, Iş Yapma Biçimimizi Şekillendiren Ve Bizi Bir Araya Getiren Temel Ortak Değerlere Bağlılığımızı Gurur Duyarak Sunuyoruz. Bu Değerler, Iş Dünyasında Ve Toplumda Sorumluluk Bilinciyle Hareket Etmeyi, Müşteri Memnuniyetini Öncelik Haline Getirmeyi, Etik Değerlere Bağlı Kalmayı Ve Teknolojik Ilerlemeyi Içermektedir.</motion.p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='w-full  relative bg-black'>
                <div className='h-1/2 w-full bg-white  absolute top-0 left-0'></div>
                <div className='grid grid-cols-6 w-full h-full relative  z-10'>
                    <div className='col-start-2 col-end-6 h-full  relative'>
                        <img src='images/about/about-banner.jpg' className='w-full '/>
                        <div className='h-full w-full mix-blend-color bg-ulusOrange top-0 left-0 absolute'></div>
                    </div>
                </div>

            </div>



            <div className='grid grid-cols-6 w-full h-full bg-black'>
                <div className='col-start-2 col-end-6 h-full  pt-10 pb-20'>
                    <div className='h-full w-full  text-18 text-white space-y-5'>
                        <motion.h2 variants={itemText} className='text-24 font-bold'>SÜRDÜRÜLEBİLİRLİK POLİTİKASI</motion.h2>
                        <motion.p variants={itemText}><span className='font-semibold'>Müşteri Memnuniyeti:</span> Ulus Olarak, Müşterilerimizin, Resmi Kuruluşların Ve Toplumun Memnuniyetini En Üst Düzeyde Tutmayı Hedefliyoruz. Tüm Faaliyetlerimizi Bu Odak Noktasıyla Gerçekleştirerek, Kaliteli Hizmet Sunmayı, Bütçe Hedeflerimizi Aşmadan Ve Belirlenen Zaman Çerçevesinde Taahhütlerimizi Yerine Getirmeyi Amaçlıyoruz.</motion.p>
                        <motion.p variants={itemText}>Taahhütlerimizin Yüksek Kalite, Hedef Bütçe Ve Zamanında Tamamlanması: Her Projemizde En Yüksek Kalite Standartlarına, Belirlenen Bütçeye Ve Zaman Çerçevesine Sadık Kalarak Taahhütlerimizi Yerine Getiriyoruz.</motion.p>
                        <motion.p variants={itemText}><span className='font-semibold'>Sürekli İyileştirme:</span> Süreçlerimizi Performans Göstergeleriyle Titizlikle Takip Ediyor Ve Olası Uyumsuzlukları Önlemek Adına Sistemimizi Sürekli Olarak Iyileştirmeye Odaklanıyoruz. Teknolojik Yenilikleri Yakından Takip Edip, Kullanılan Malzeme Ve Imalat Tekniklerini Sürekli Geliştirerek Sektördeki En Iyi Uygulamaları Benimsiyoruz.</motion.p>
                    <motion.p variants={itemText}><span className='font-semibold'>Kanunlara Ve Mevzuatlara Uyum:</span> Tüm Faaliyetlerimizi Türkiye Cumhuriyeti Yasaları Ve Hukuk Kuralları Çerçevesinde, Ilgili Yönetmelikler, Düzenlemeler Ve Şartnamelere Uygun Olarak Gerçekleştiriyoruz.</motion.p>
                    <motion.p variants={itemText}><span className='font-semibold'>Yüksek Nitelikli Çalışanlar:</span> İşimize Hakim, Bilgili, Işlerine Bağlı, Sorumluluk Sahibi Ve Hedeflere Odaklı Nitelikli Bir Ekibiz. Aynı Prensipleri Taşeronlarımızla Paylaşarak Onların Gelişimine Katkı Sağlıyor Ve Işbirliği Içinde Çalışıyoruz.</motion.p>
                    <motion.p variants={itemText}><span className='font-semibold'>İnsan Sağlığını Koruma:</span> Projelerimizde Ve Hizmet Süreçlerimizde Insan Sağlığını Ve Güvenliğini Ön Planda Tutuyoruz. Olası Riskleri Azaltmayı Ve Çalışanlarımız Ile Toplumu Bu Konuda Bilinçlendirmeyi Asli Görevimiz Olarak Kabul Ediyoruz.</motion.p>
                    <motion.p variants={itemText}><span className='font-semibold'>Çevreyi Koruma:</span> Tüm Projelerimizde Çevre Dostu Bir Yaklaşım Benimseyerek, Doğal Kaynakları En Verimli Şekilde Kullanarak Çevreye En Az Etkiyi Bırakmayı Amaçlıyoruz. Atıklarımızı Azaltmak Için Proaktif Bir Yaklaşım Sergiliyor Ve Olumsuz Çevresel Etkileri En Aza Indirmek Için Çaba Sarf Ediyoruz.</motion.p>
                    <motion.p variants={itemText}>Ulus Olarak, Işimizi Sürdürülebilirlik, Müşteri Memnuniyeti Ve Toplumsal Sorumluluk Bilinciyle Şekillendiriyoruz.</motion.p>
                    
                    </div>
                </div>

            </div>


        </motion.div>
    )
}

export default Hakkimizda