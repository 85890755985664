import React from 'react'

import { motion } from 'framer-motion'


const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
    hidden: { opacity: 1 },
  };

const itemText = {visible: { opacity: 1, transition: { duration: 1 } } , hidden: { opacity: 0 }};
const itemButton = { visible: { opacity:1,scale: 1, transition: { duration: 1 } }, hidden: {scale:1.1 ,opacity: 0 }, }

const Iletisim = () => {
    return (
        <motion.div  initial="hidden" whileInView="visible" variants={list} className='grid grid-cols-6 py-40  px-[16.6%] md:px-[0%] space-y-20 md:space-y-0'>
            <div className='col-span-6 md:col-start-2 md:col-end-2 h-full   '>
                <img src='/images/iletisim/fly.svg' />
                <div className=''>
                    <div className='my-7'>
                        <motion.h5 variants={itemText} className='text-35 font-semibold'>İLETİŞİM</motion.h5>
                        <div className='h-1 w-12 bg-ulusOrange'></div>
                    </div>
                    <div className='text-16 font font-light space-y-1 mb-7'>
                        <motion.a variants={itemText} className='block'>0 258 213 77 44</motion.a>
                        <motion.a variants={itemText} className='block'>info@ulusinsaat.com</motion.a>
                        <motion.a variants={itemText} className='block'>Mehmetçik Mah. Muammer Aksoy Cad.

                            No: 89, 20100 Pamukkale/Denizli</motion.a>

                    </div>
                    <a href='https://maps.app.goo.gl/G56WMGJhHmQbxj3r9' target='_blank' className='block'>
                    <motion.div whileHover={{scale:1.1}} variants={itemButton} className="  inline-flex space-x-2 py-2 px-6 rounded-3xl border border-black ">
                            <h6 className="text-16  text-black font-light">Yol Tarifi Al</h6>

                        </motion.div>
                        </a>

                </div>

            </div>
        
            <motion.div variants={itemText} className=' col-span-6 md:col-start-3 md:col-end-6  h-full md:pl-20 '>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2652.5722689214485!2d29.09025809589236!3d37.755862146890195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c73f1d07a2933b%3A0x815919c41fe0242b!2zVWx1cyDEsG7Fn2FhdCBMdGQuxZ50aQ!5e0!3m2!1sen!2str!4v1722172550399!5m2!1sen!2str" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </motion.div>


        </motion.div>
    )
}

export default Iletisim